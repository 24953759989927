.complete-screen.flex .header-wrapper {
  flex: 0 0 auto;
}

.header-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  height: 70px;
  padding: 20px 30px;
  background: #294753;
}

.header-title-wrapper {
  display: inline-flex;
  align-items: center;
  font-size: 18px;
  color: #fff;
  padding-right: 20px;
  margin-right: auto;
}

.header-title,
.header-title-wrapper {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.complete-screen.flex .complete-content {
  width: 100%;
}

.content {
  max-width: 500px;
  width: 100%;
  margin: 0 auto;
  padding: 30px 0px;
  position: relative;
}


.complete-content__time {
  display: inline-flex;
  align-items: center;
  line-height: 1;
  font-size: 20px !important;
  min-width: 200px;
}

@media screen and (max-width: 600px) {
  .complete-content__time {
    align-items: center;
  }
}

.complete-content__time.empty {
  font-size: 30px;
}

.complete-content__time--nums {
  font-size: 50px;
  font-weight: 300;
  text-align: center;
}

.complete-content__time--am-pm {
  font-size: 22px;
  font-weight: 300;
  padding-left: 10px
}

.complete-content__date {
  display: block;
  font-size: 34px;
  padding-bottom: 20px;
  margin-top: 20px;
}

.complete-content-inner {
  height: 100%;
}

.complete-content-title-wrapper {
  display: flex;
  align-items: center;
}

.complete-content-title-wrapper .content-title {
  margin-left: 20px;
}

.complete-content__title {
  display: block;
  font-size: 24px;
  font-weight: 700;
  line-height: 1.1;
}

.complete-content__title.bordered {
  border-bottom: 1px solid #c0c8cc;
  padding-bottom: 23px;
}

.complete-content__title.xl {
  font-size: 28px;
}

.complete-content__subtitle {
  display: inline-block;
  margin: 20px 0 10px;
}

.complete-content__details {
  padding-right: 30px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

@media screen and (max-width: 600px) {
  .complete-content__details {
    padding-right: 0;
  }
}

.complete-content__details-wrapper {
  display: flex;
  gap: 20px;
  padding: 10px;
  flex-direction: row;
  border-radius: 10px;
}
.complete-content__details-wrapper:nth-child(odd) {
  background: #0392A2;
  color: white;
}

/* .complete-content__details-wrapper:last-child {
  padding-bottom: 0;
} */

@media screen and (max-width: 600px) {
  .complete-content__details-wrapper {
    flex-direction: row;
  }
}

.complete-content__details--title {
  display: block;
  font-size: 18px;
  font-weight: 700;
  padding-bottom: 10px;
}

.complete-screen.flex .complete-content {
  width: 100%;
}

@media screen and (max-width: 600px) {
  .content {
    padding: 0px 20px;
  }

  .content {
    max-width: 920px;
    width: 100%;
    margin: 0 auto;
    /* padding: 30px; */
    position: relative;
  }
}

.main-container,
.max-height,
body,
html {
  min-height: 100%;
  height: 100%;
}

.complete-screen {
  height: 100%;
}
.complete-screen.flex {
  display: flex;
  flex-direction: column;
}
.button-standard {
  padding: 5px 10px;
  min-height: 40px;
  font-weight: 700;
  position: relative;
}
.button-standard:focus:before {
  content: "";
  position: absolute;
  left: -3px;
  top: -3px;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  border-radius: 3px;
  border: 2px solid #294753;
}
.button-standard:not([disabled]):active {
  transform: translateY(2px);
}
.button-standard.min-width {
  min-width: 120px;
}
.button-standard:active:after {
  opacity: 0.3;
}
.complete-screen.flex .button-standard {
  font-weight: 700;
  font-size: 20px;
}

.content-title {
  font-size: 20px;
  font-weight: 700;
}
.content-title.xl {
  font-size: 28px;
  line-height: 1.2;
}
.content-title-block {
  display: block;
  padding-bottom: 10px;
}
.content-title-wrapper {
  display: flex;
  align-items: center;
}
.content-inner {
  margin: 25px 0 50px;
}
.content-inner.half-width {
  width: 50%;
}
@media screen and (max-width: 600px) {
  .content-inner.half-width {
    width: 100%;
  }
}
.content.with-padding-bottom {
  padding-bottom: 10%;
}
@media screen and (max-width: 600px) {
  .content.with-padding-bottom {
    padding-bottom: 0;
  }
}
.content-with-bg {
  position: relative;
  padding: 25px;
  margin-bottom: 20px;
  border-radius: 8px;
}
.content-with-bg:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #294753;
  opacity: 0.1;
  z-index: 0;
  border-radius: 8px;
}
.content-with-bg-inner {
  position: relative;
  z-index: 1;
}
.content-with-bg .content-title-wrapper {
  margin-bottom: 10px;
}
.content-with-bg .content-title {
  font-size: 24px;
}
@media screen and (max-width: 600px) {
  .content-with-bg .content-title {
    font-size: 22px;
  }
}
.content-with-bg .content-inner {
  margin: 0;
  font-size: 16px;
}
.content-with-bg p {
  margin: 0;
  padding: 0;
}
.content-highlight-title {
  margin-bottom: 20px;
  font-weight: 700;
  font-size: 21px;
}
.complete-content__details--provider {
  font-size: 25px;
  padding: 10px 0px;
}
.complete-content__details--address {
  font-size: 25px;
  white-space: pre-wrap;
  margin: 0;
  overflow: visible;
  line-height: 30px;
}
.complete-control {
  width: 100%;
  flex: 0 1 auto;
}
.complete-control__title {
  margin: 10px 0;
  font-style: italic;
  opacity: 0.6;
}
.complete-control__subtitle {
  margin: -5px 0 10px;
  font-weight: 700;
}
.complete-control div .button-standard {
  flex: 0 0 auto;
  margin: 15px 15px 0 0;
  font-size: 20px !important;

}
.complete-control .button-standard:last-child {
  margin-right: 0;
}
@media screen and (max-width: 600px) {
  .complete-control div .button-standard {
    flex: 1 1 auto;
    padding: 0 10px;
  }
}

.complete-control__buttons {
  display: flex;
  flex-wrap: wrap;
}
.complete-control__buttons .button-standard {
  padding: 5px 30px;
  width: 265px;
}
@media screen and (max-width: 600px) {
  .complete-control__buttons {
    flex-direction: column;
  }
  .complete-control__buttons .button-standard {
    margin: 15px 0 0;
    width: auto;
  }
}
@supports (grid-gap: 0) {
  .complete-control__buttons {
    display: grid;
    grid-gap: 15px;
  }
  .complete-control__buttons .button-standard {
    margin: 0 !important;
    width: auto;
  }
}
.complete-control.content {
  padding-bottom: 0;
}
@media screen and (max-width: 600px) {
  .complete-control.content {
    padding-top: 0;
  }
}

.button-green {
  background: green;
  color: #fff;
  border: none;
  font-size: 25px !important;
  min-height: 60px;
  border-radius: 10px;

}
.button-green:hover {
  background: green;
  font-size: 20px !important;

}
.button-green[disabled],
.button-green[disabled]:hover {
  background: greenyellow;
  color: #fff;
  font-size: 20px !important;

}

.button-green .button-inner-text {
  font-size: 26px;
}

.button-yellow {
  background: #d6d600;
  color: #000;
  border: none;
  font-size: 25px !important;
  min-height: 60px;
  border-radius: 10px;

}
.button-yellow:hover {
  background: yellow;
  font-size: 20px !important;

}
.button-yellow[disabled],
.button-yellow[disabled]:hover {
  background: yellow;
  color: #fff;
  font-size: 20px !important;

}
.button-yellow .button-inner-text {
  font-size: 26px;
}
.button-red {
  background: red;
  color: #fff;
  border: none;
  font-size: 20px !important;
  min-height: 60px;
  border-radius: 10px;
}
.button-red:hover {
  background: #e8470b;
  font-size: 20px !important;

}
.button-red[disabled],
.button-red[disabled]:hover {
  background: #f78a62;
  color: #fff;
  font-size: 20px !important;

}
.button-red .button-inner-text {
  font-size: 26px;
}
body[dir="rtl"] .complete-control .button-standard:last-child {
  margin-right: 20px;
}
@media screen and (max-width: 600px) {
  body[dir="rtl"] .complete-control .button-standard:last-child {
    margin-right: 0;
  }
}
body[dir="rtl"] .complete-control .button-standard:first-child {
  margin-right: 0;
}

.button-highlight {
  border: 2px solid #333;
  outline: none;
  box-shadow: 0 0 15px darkorange;
}

.button-highlight.button-green {
  background-color: #94e394;
  color: black;
}

.appointment__status {
  display: block;
  font-size: 50px;
  margin-bottom: 20px;
  padding: 15px;
  color: white;
}

.appointment-cancelled {
  background: red;
  padding: 10px 15px;
  margin-top: 20px;
  border-radius: 10px;
  line-height: 70px;
}

.appointment-confirmed {
  background: green;
  padding: 10px 15px;
  margin-top: 20px;
  border-radius: 10px;
  line-height: 70px;
}

.appointment-reschedule {
  background: orange;
  padding: 10px 15px;
  margin-top: 20px;
  border-radius: 10px;
  line-height: 70px;
}

.complete-content__details--address a {
  color: black;
}