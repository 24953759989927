.timer-wrapper {
    display: flex;
    position: fixed;
    top: 0px;
    width: 100%;
    background-color: red;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 80px;
    z-index: 999;
}

.timer-wrapper h3 {
    margin-bottom: 0px;
}