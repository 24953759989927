.custom-select-box {
    width: 100%;
    padding: 10px;
}

.custom-select-box .ant-select {
    width: 100%;
}

.custom-select-box .ant-select .ant-select-selector,
.custom-select-box .ant-select-selection-search input {
    height: 80px !important;
}

.custom-select-box .ant-select-selection-placeholder,
.custom-select-box .ant-select-selection-item {
    line-height: 80px !important;
    font-weight: bold;
    font-size: 20px;
    text-transform: uppercase;
}

.custom-select-box .ant-select-item-option-content {
    font-size: 20px;
    min-height: 30px;
    text-align: center;
    text-transform: uppercase;
}

.custom-select-box .ant-select-selector {
    text-align: center;
}

.ant-select-item.ant-select-item-option {
    font-size: 20px;
    font-weight: bold;
    padding: 20px 10px;
    text-align: center;
}

.appointmentSlots {
    flex-grow: 1;
    flex: 1 1 auto;
    font-size: 14px;
    height: 0;
    margin: 16px 0 8px;
    height: auto;
}

.appointmentSlotsContainer {
    align-content: flex-start;
    display: flex;
    flex-wrap: wrap;
    height: 100%;
}

.appointmentSlot {
    align-items: center;
    background-color: #fff;
    border: 1px solid #05b6d8;
    border-radius: 6px;
    font-weight: bold;
    color: #05b6d8;
    cursor: pointer;
    display: flex;
    font-size: 16px;
    height: 44px;
    justify-content: center;
    margin: 4px;
    text-align: center;
    transition: .3s;
    width: calc(33% - 7px);
    text-transform: uppercase;
}

.appointmentSlot.active {
    background-color: #05b6d8;
    color: #fff;
}

.slots-title {
    text-align: center;
    font-size: 30px;
    font-weight: bold;
    text-transform: uppercase;
    margin-top: 10px;
}

.width-100 {
    width: 100%;
}

.request-worker {
    background-color: lightgray;
    color: black;
    width: 100%;
}

.any-worker {
    background-color: yellow !important;
    color: black !important;
    width: 100%;
}