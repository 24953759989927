.date-picker {
    height: 80px;
    width: 100%;
}

.date-picker input {
    font-weight: bold;
    font-size: 28.5px !important;
    text-transform: uppercase;
    text-align: center;
}