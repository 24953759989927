a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
big,
blockquote,
body,
canvas,
caption,
center,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
output,
p,
pre,
q,
ruby,
s,
samp,
section,
small,
span,
strike,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
tt,
u,
ul,
var,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    -webkit-box-sizing: border-box;
    box-sizing: border-box
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block
}

body {
    line-height: 1
}

ol,
ul {
    list-style: none
}

blockquote,
q {
    quotes: none
}

blockquote:after,
blockquote:before,
q:after,
q:before {
    content: "";
    content: none
}

table {
    border-collapse: collapse;
    border-spacing: 0
}

input,
select,
textarea {
    -webkit-box-sizing: border-box;
    box-sizing: border-box
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin-bottom: 0.5rem
}

.container,
.flex .flex__col,
.flex .flex__col--1,
.flex .flex__col--2,
.flex .flex__col--3,
.flex .flex__col--4,
.flex .flex__col--5,
.flex .flex__col--6,
.flex .flex__col--7,
.flex .flex__col--8,
.flex .flex__col--9,
.flex .flex__col--10,
.flex .flex__col--11,
.flex .flex__col--12 {
    padding-left: 15px;
    padding-right: 15px
}

@media screen and (max-width:992px) {

    .container,
    .flex .flex__col,
    .flex .flex__col--1,
    .flex .flex__col--2,
    .flex .flex__col--3,
    .flex .flex__col--4,
    .flex .flex__col--5,
    .flex .flex__col--6,
    .flex .flex__col--7,
    .flex .flex__col--8,
    .flex .flex__col--9,
    .flex .flex__col--10,
    .flex .flex__col--11,
    .flex .flex__col--12 {
        padding-left: 10px;
        padding-right: 10px
    }
}

html {
    font-size: 16px;
    color: #202020
}

@media screen and (max-width:768px) {
    html {
        font-size: 14px
    }
}

body {
    background-color: #ffffff;
    font-family: "Fira Sans", Helvetica, Arial, sans-serif;
    font-size: 1rem;
    line-height: 1.25em;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

main {
    position: relative
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 600;
    line-height: 1.125em;
    margin-bottom: 0px
}

h1 {
    font-size: 3.5rem
}

h2 {
    font-size: 2rem
}

h3 {
    font-size: 2.5rem
}

h4 {
    font-size: 2.25rem
}

h5 {
    font-size: 1.875rem
}

h6 {
    font-size: 1.5rem
}

strong {
    font-weight: 600
}

.text--left {
    text-align: left
}

.text--right {
    text-align: right
}

.text--center {
    text-align: center
}

.flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap
}

.flex--ai-center {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.flex--ai-start {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start
}

.flex--ai-end {
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end
}

.flex--jc-sbetween {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
}

.flex--jc-around {
    -ms-flex-pack: distribute;
    justify-content: space-around
}

.flex--jc-center {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.flex--jc-end {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end
}

.flex--dir-column {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column
}

.flex--wrap {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}

.flex--row {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px
}

@media screen and (max-width:992px) {
    .flex--row {
        margin-left: -10px;
        margin-right: -10px
    }
}

.flex .flex__col,
.flex .flex__col--1,
.flex .flex__col--2,
.flex .flex__col--3,
.flex .flex__col--4,
.flex .flex__col--5,
.flex .flex__col--6,
.flex .flex__col--7,
.flex .flex__col--8,
.flex .flex__col--9,
.flex .flex__col--10,
.flex .flex__col--11,
.flex .flex__col--12 {
    margin-bottom: 15px
}

.flex__col--12 {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    width: 100%
}

.flex__col--11 {
    -ms-flex-preferred-size: 90%;
    flex-basis: 90%;
    width: 90%
}

.flex__col--10 {
    -ms-flex-preferred-size: 83.33%;
    flex-basis: 83.33%;
    width: 83.33%
}

.flex__col--9 {
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
    width: 75%
}

@media screen and (max-width:1200px) {
    .flex__col--9 {
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
        width: 100%
    }
}

.flex__col--8 {
    -ms-flex-preferred-size: 66.66%;
    flex-basis: 66.66%;
    width: 66.66%
}

@media screen and (max-width:992px) {
    .flex__col--8 {
        -ms-flex-preferred-size: 50%;
        flex-basis: 50%;
        width: 50%
    }
}

@media screen and (max-width:768px) {
    .flex__col--8 {
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
        width: 100%
    }
}

.flex__col--7 {
    -ms-flex-preferred-size: 58.33%;
    flex-basis: 58.33%;
    width: 58.33%
}

@media screen and (max-width:992px) {
    .flex__col--7 {
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
        width: 100%
    }
}

.flex__col--6 {
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    width: 50%
}

@media screen and (max-width:992px) {
    .flex__col--6 {
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
        width: 100%
    }
}

.flex__col--5 {
    -ms-flex-preferred-size: 41.66%;
    flex-basis: 41.66%;
    width: 41.66%
}

@media screen and (max-width:992px) {
    .flex__col--5 {
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
        width: 100%
    }
}

.flex__col--4 {
    -ms-flex-preferred-size: 33.33%;
    flex-basis: 33.33%;
    width: 33.33%
}

@media screen and (max-width:992px) {
    .flex__col--4 {
        -ms-flex-preferred-size: 50%;
        flex-basis: 50%;
        width: 50%
    }
}

@media screen and (max-width:768px) {
    .flex__col--4 {
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
        width: 100%
    }
}

.flex__col--3 {
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
    width: 25%
}

@media screen and (max-width:992px) {
    .flex__col--3 {
        -ms-flex-preferred-size: 50%;
        flex-basis: 50%;
        width: 50%
    }
}

@media screen and (max-width:768px) {
    .flex__col--3 {
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
        width: 100%
    }
}

.flex__col--2 {
    -ms-flex-preferred-size: 16.66%;
    flex-basis: 16.66%;
    width: 16.66%
}

.flex__col--1 {
    -ms-flex-preferred-size: 10%;
    flex-basis: 10%;
    width: 10%
}

.flex__col {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1
}

.flex__col--full {
    margin-bottom: 0 !important;
    padding-left: 0 !important;
    padding-right: 0 !important
}

.flex__content {
    margin-bottom: 20px
}

.flex__content__image {
    -ms-flex-preferred-size: 250px;
    flex-basis: 250px;
    margin-right: 20px
}

.flex__content__image img {
    width: 100%
}

@media screen and (max-width:768px) {
    .flex__content__image {
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%
    }
}

.flex__content__text {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    font-size: 1.125rem;
    line-height: 1.5em
}

.flex__content__text p {
    margin-bottom: 20px
}

.container {
    width: 100%;
    max-width: 1366px;
    margin-left: auto;
    margin-right: auto
}

a:focus,
button:focus,
input:focus {
    outline: none
}

svg {
    height: 1.25rem;
    vertical-align: middle;
    margin-top: -2px
}

svg path {
    fill: #202020
}

@-webkit-keyframes loader-fadeout {
    0% {
        z-index: 500;
        opacity: 1
    }

    90% {
        opacity: 0
    }

    to {
        z-index: -1
    }
}

@keyframes loader-fadeout {
    0% {
        z-index: 500;
        opacity: 1
    }

    90% {
        opacity: 0
    }

    to {
        z-index: -1
    }
}

.form__group {
    margin-bottom: 20px
}

.form__input {
    font-family: "Fira Sans", Helvetica, Arial, sans-serif;
    font-weight: 500;
    color: #202020;
    font-size: 1.375rem;
    height: 5rem;
    line-height: 5rem;
    border: solid 1px #aeaeae;
    border-radius: 5px;
    width: 100%;
    padding-left: 30px;
    padding-right: 30px;
    -webkit-transition: all 300ms ease-out;
    transition: all 300ms ease-out
}

.form__input:focus,
.form__input:hover {
    border: solid 2px #202020
}

.form__picker .css-1pahdxg-control,
.form__picker .css-yk16xz-control {
    height: 5rem;
    border: solid 1px #aeaeae !important;
    border-radius: 5px;
    padding-left: 20px;
    padding-right: 0
}

.form__picker .css-g1d714-ValueContainer {
    font-size: 1.375rem;
    font-weight: 500
}

.form__picker .css-1uccc91-singleValue {
    height: 1.375rem;
    font-size: 1.375rem;
    font-weight: 500
}

.form__picker .css-1okebmr-indicatorSeparator {
    margin-top: 15px;
    margin-bottom: 15px
}

.form__picker svg {
    height: 30px;
    width: 30px
}

.button {
    font-family: "Fira Sans", Helvetica, Arial, sans-serif;
    height: 5.625rem;
    min-width: 380px;
    border-radius: 5px;
    font-size: 1.375rem;
    line-height: 5.625rem;
    font-weight: 600;
    border: none;
    cursor: pointer
}

@media screen and (max-width:768px) {
    .button {
        min-width: 200px;
        width: 100%
    }
}

.button__icon path {
    fill: #05b6d8
}

.button--bg {
    position: relative;
    background-color: #05b6d8;
    color: #ffffff;
    text-transform: uppercase
}

.button--bg--success {
    background-color: #3da231
}

.button--bg--error {
    background-color: red;
    color: white;
}

.button--bg:before {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.2);
    opacity: 0;
    -webkit-transition: all 400ms ease;
    transition: all 400ms ease
}

.button--bg:focus:before,
.button--bg:hover:before {
    opacity: 1
}

.button--outline {
    background: transparent;
    border: solid 1px #05b6d8;
    color: #05b6d8;
    text-transform: uppercase;
    -webkit-transition: all 400ms ease;
    transition: all 400ms ease
}

.button--outline:focus,
.button--outline:hover {
    background-color: #05b6d8;
    color: #ffffff
}

.button--link {
    height: 1.375rem;
    line-height: 1.5rem;
    background: none;
    font-size: 1.375rem;
    font-weight: 500;
    color: #05b6d8;
    min-width: auto;
    -webkit-transition: all 400ms ease;
    transition: all 400ms ease;
    margin-bottom: 20px
}

.button--link:focus,
.button--link:hover {
    opacity: 0.7
}

.header {
    position: relative;
    overflow-x: hidden;
    min-height: 200px
}

@media screen and (max-width:992px) {
    .header {
        padding-left: 10px;
        padding-right: 10px
    }
}

.header.hidden {
    display: none
}

.header a {
    color: #05b6d8;
    text-decoration: none
}

.header__bg {
    position: absolute;
    z-index: 0;
    width: 1080px;
    height: 1380px;
    top: -1180px;
    left: 50%;
    margin-right: -50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    border-radius: 50%;
    background-color: #05b6d8
}

@media screen and (max-width:768px) {
    .header__bg {
        top: -1250px
    }
}

.header__business {
    position: relative;
    z-index: 1;
    padding-top: 30px;
    padding-bottom: 10px
}

.header__business__name {
    font-weight: 300;
    text-transform: uppercase;
    margin-top: 10px
}

@media screen and (max-width:992px) {
    .header__business__name {
        font-size: 2.75rem;
        margin-bottom: 10px
    }
}

.header__business__logo {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    overflow: hidden;
    border: solid 3px;
    border-color: #05b6d8;
    background-color: black;
    margin-left: auto;
    margin-right: auto
}

@media screen and (max-width:768px) {
    .header__business__logo {
        width: 150px;
        height: 150px
    }
}

.header__business__logo__image {
    width: 200px;
    height: 200px;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: center center;
    object-position: center center
}

@media screen and (max-width:768px) {
    .header__business__logo__image {
        width: 150px;
        height: 150px
    }
}

.header__business__logo__text {
    color: #202020;
    line-height: 200px
}

.header__business__icon {
    display: inline-block;
    margin-right: 5px
}

.header__business__icon path {
    fill: #05b6d8
}

.header__business__address {
    font-weight: 500;
    font-size: 30px;
    color: #05b6d8;
    margin-bottom: 10px !important;
}


.header__business__contact {
    font-weight: 500;
    font-size: 1rem;
    color: #05b6d8
}

@media screen and (max-width:992px) {
    .header__business__contact {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column
    }
}

.header__business__contact--phone {
    margin-right: 20px;
    font-size: 30px;
    margin-top: 10px;
}

.header__business__contact--email svg {
    height: 1rem
}

.footer {
    margin-top: 40px;
    padding-top: 20px;
    padding-bottom: 20px
}

.footer__text {
    font-size: 0.875rem;
    text-transform: uppercase
}

.main {
    position: relative;
    margin-top: 20px
}

.main__title {
    color: #05b6d8
}

.appointment {
    position: relative
}

.appointment__form {
    margin-top: 20px;
    margin-bottom: 20px
}

@media screen and (max-width:992px) {
    .appointment__form {
        margin-bottom: 20px
    }
}

.appointment__form__title {
    font-weight: 300;
    margin-bottom: 20px;
    text-align: center;
}

.appointment__form__note {
    font-weight: 500;
    font-size: 1.125rem;
    margin-bottom: 10px;
    text-align: center;
}

.appointment__form__date {
    margin-bottom: 60px
}

.appointment__summary {
    margin-top: 25px
}

.appointment__summary__header {
    background-color: #05b6d8;
    height: 70px;
    border-radius: 5px 5px 0 0;
    padding-left: 25px;
    padding-right: 25px;
    text-align: center;

}

@media screen and (max-width:768px) {
    .appointment__summary__header {
        height: 60px
    }
}

.appointment__summary__header__title {
    line-height: 70px;
    color: #ffffff;
    font-size: 30px;
}

@media screen and (max-width:768px) {
    .appointment__summary__header__title {
        line-height: 60px
    }
}

.appointment__summary__body {
    background-color: #fcfcfc;
    min-height: 150px
}

.appointment__summary__footer {
    border-top: solid 1px #05b6d8;
    margin-left: 20px;
    margin-right: 20px;
    padding-top: 20px;
    padding-bottom: 20px
}

.appointment__summary__footer__title {
    font-weight: 600;
    text-transform: uppercase;
    font-size: 1rem;
    margin-bottom: 2px
}

.appointment__summary__footer__total {
    color: #05b6d8
}

.appointment__summary__footer__disclaimer {
    margin-top: 15px
}

.appointment__final {
    margin-top: 40px
}

.appointment__final__thank {
    font-weight: 300;
    font-size: 4.125rem;
    margin-bottom: 30px
}

@media screen and (max-width:992px) {
    .appointment__final__thank {
        font-size: 2.25rem
    }
}

.appointment__final__reference {
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 3rem;
    color: #05b6d8;
    margin-bottom: 20px
}

.appointment__final__reference span {
    display: block;
    font-size: 5rem
}

@media screen and (max-width:768px) {
    .appointment__final__reference span {
        font-size: 4rem
    }
}

.appointment__final__remarks {
    color: #05b6d8;
    font-weight: 400;
    margin-bottom: 50px
}

@media screen and (max-width:992px) {
    .appointment__final__remarks {
        font-size: 1.5rem
    }
}

.appointment__final__button {
    margin-bottom: 20px
}

.appointment__error {
    margin-top: 40px;
    margin-bottom: 40px;
    padding-left: 40px;
    padding-right: 40px
}

.appointment__error__image {
    max-width: 900px;
    width: 100%
}

.service {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px
}

.service__row {
    margin-bottom: 10px
}

.service__header {
    color: #05b6d8
}

.service__header h6 {
    margin-bottom: 20px
}

@media screen and (max-width:992px) {
    .service__header {
        display: none
    }
}

.service__remove {
    text-align: left
}

@media screen and (max-width:768px) {
    .service__remove {
        text-align: center
    }
}

.summary {
    position: relative;
    margin-bottom: 0px;
    text-align: center;
}

.summary__date {
    font-size: 30px;
    font-weight: 500;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 25px;
    padding-right: 25px;
    text-transform: uppercase;
}

.summary__services {
    padding-bottom: 10px;
    
}

.summary__services__item {
    border-bottom: 1px solid #ececec;
    margin-left: 25px;
    padding-left: 5px;
    margin-right: 25px;
    margin-bottom: 10px;
    text-transform: uppercase;
}

.summary__services__item__service {
    font-size: 30px;
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 15px
}

.summary__services__item__info {
    display: inline-block;
    font-size: 30px;
    font-weight: 500;
    margin-right: 20px
}

@media screen and (max-width:768px) {
    .summary__services__item__info {
        display: block;
        font-weight: 400;
        margin-right: 0;
        margin-bottom: 10px
    }
    .summary__services__item__service {
        margin-bottom: 10px;
    }
    .summary__date {
        padding-top: 10px;
        padding-bottom: 10px;
        line-height: 20px;
    }
    .summary__serivices {
        line-height: 25px;
    }
    .appointment__summary__body {
        min-height: 110px;
    }
}

.summary__services__item:last-child {
    border: none
}

.user-acceptance {
    font-size: 0.9375rem;
    margin-top: 20px;
    margin-bottom: 20px
}

#summary {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 60px;
}

@media screen and (max-width:768px) {
    #summary {
        padding-top: 0px;
        padding-bottom: 0px
    }
}

.birth_date_block .DayPickerInput {
    display: block
}

.appointmentDetailsMain {
    font-family: "Poppins";
    font-size: 1rem;
    min-width: 375px;
    max-width: 1440px;
    margin: 2rem auto;
    padding-top: 1.5rem
}

.appointmentDetailsMain .info-panel {
    padding: 0 2rem
}

.appointmentDetailsMain .info-panel .greeting-header .greeting-title {
    font-weight: 700;
    font-size: 1.5rem;
    color: #25313E
}

.appointmentDetailsMain .info-panel .greeting-header .greeting-sub {
    font-size: 1rem;
    margin-top: 0.5rem
}

.appointmentDetailsMain .service-panel {
    padding: 1rem 2rem
}

.appointmentDetailsMain .service-panel .header {
    padding-top: 1rem
}

.appointmentDetailsMain .service-panel .header .title {
    font-weight: 700;
    font-size: 1.5rem;
    color: #25313E
}

.appointmentDetailsMain .service-panel .header .actions {
    margin-top: 1rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.appointmentDetailsMain .service-panel .header .actions .btn {
    width: 50%
}

.appointmentDetailsMain .service-panel .header .actions .btn:first-of-type {
    border-radius: 25px 0px 0px 25px
}

.appointmentDetailsMain .service-panel .header .actions .btn:nth-of-type(2) {
    border-radius: 0px 25px 25px 0px
}

@media screen and (min-width:768px) {
    .appointmentDetailsMain .service-panel .header {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center
    }

    .appointmentDetailsMain .service-panel .header .actions {
        margin: 0
    }

    .appointmentDetailsMain .service-panel .header .actions .btn {
        width: 150px
    }
}

.appointmentDetailsMain .card {
    padding: 1.5rem;
    border-radius: 0.75rem;
    margin: 1.5rem 0;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)
}

.appointmentDetailsMain .card__customer {
    background: #FFF3F8
}

@media screen and (min-width:768px) and (max-width:1023px) {
    .appointmentDetailsMain .card__customer {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between
    }
}

.appointmentDetailsMain .card__merchant .header {
    padding: 0
}

.appointmentDetailsMain .card__merchant .header .business-thumb {
    display: none;
    background: #ececec;
    width: 100px;
    height: 100px;
    overflow: hidden;
    border-radius: 50%
}

.appointmentDetailsMain .card__merchant .header .business-thumb img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover
}

.appointmentDetailsMain .card__merchant .header .business-name {
    margin-bottom: 0
}

@media screen and (min-width:768px) {
    .appointmentDetailsMain .card__merchant {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        padding: 0
    }

    .appointmentDetailsMain .card__merchant .header {
        width: 40%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        background: #FFF3F8;
        padding: 0 1.5rem;
        text-align: center
    }

    .appointmentDetailsMain .card__merchant .business-thumb {
        display: block !important
    }

    .appointmentDetailsMain .card__merchant .business-name>.info__label {
        display: none
    }

    .appointmentDetailsMain .card__merchant .body {
        padding: 1.5rem
    }
}

@media screen and (min-width:1024px) {
    .appointmentDetailsMain .card__merchant {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column
    }

    .appointmentDetailsMain .card__merchant .operation-hours {
        display: block
    }

    .appointmentDetailsMain .card__merchant .header {
        width: 100%;
        padding: 1.5rem
    }
}

.appointmentDetailsMain .card__appointment {
    padding: 0
}

.appointmentDetailsMain .card__appointment .header {
    padding: 1rem 1.5rem;
    background: #FFF3F8;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
}

@media screen and (max-width:767px) {
    .appointmentDetailsMain .card__appointment .header .lead {
        font-size: 1rem
    }
}

.appointmentDetailsMain .card__appointment .body {
    padding: 1rem 1.5rem
}

.appointmentDetailsMain .card__appointment .body .service {
    margin-bottom: 1.5rem
}

.appointmentDetailsMain .card__appointment .body .service__time {
    margin-top: 0.5rem
}

@media screen and (min-width:700px) {
    .appointmentDetailsMain .card__appointment .body .service__time {
        margin: 0
    }
}

.appointmentDetailsMain .card__appointment .body .service .highlight {
    color: #05b6d8;
    font-weight: 700;
    font-size: 0.85rem
}

.appointmentDetailsMain .card__appointment .info__label {
    text-transform: lowercase
}

.appointmentDetailsMain .card__appointment--main {
    position: relative;
    margin-bottom: 3rem
}

.appointmentDetailsMain .card__appointment--main:after {
    content: " ";
    height: 1px;
    width: 100%;
    border-bottom: 1px dashed #EBBBCF;
    position: absolute;
    bottom: -2rem
}

.appointmentDetailsMain .card__appointment--main .header {
    background: #EBBBCF;
    border-radius: 1rem 1rem 0 0
}

.appointmentDetailsMain .info {
    margin: 1rem 0
}

.appointmentDetailsMain .info__label {
    text-transform: uppercase;
    font-size: 0.65rem;
    letter-spacing: 1px;
    font-weight: 700;
    color: #05b6d8
}

.appointmentDetailsMain .info__value {
    margin-top: 0.5rem
}

.appointmentDetailsMain .info__value a {
    color: #25313E;
    font-weight: 900
}

.appointmentDetailsMain .info__value.lead {
    font-weight: 700;
    color: #25313E;
    font-size: 1.5rem
}

.appointmentDetailsMain .info__flex {
    margin-top: 0.5rem
}

@media screen and (min-width:700px) {
    .appointmentDetailsMain .info__flex {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between
    }
}

.appointmentDetailsMain .operation-hours {
    display: none
}

.appointmentDetailsMain .btn {
    border-radius: 25px;
    padding: 0.75rem 2rem;
    background: #FFF3F8;
    outline: none;
    border: none;
    cursor: pointer;
    color: #25313E;
    font-weight: 900;
    text-transform: lowercase;
    -webkit-transition: 0.2s ease-in-out;
    transition: 0.2s ease-in-out
}

.appointmentDetailsMain .btn.active {
    background: #EBBBCF;
    color: #ffffff
}

.appointmentDetailsMain .btn:hover {
    background: #EBBBCF;
    color: #ffffff
}

.appointmentDetailsMain .btn.directions {
    margin-top: 1rem;
    background: #EBBBCF;
    text-decoration: none;
    color: #25313E;
    display: inline-block;
    font-weight: 400
}

.appointmentDetailsMain .btn.directions:hover {
    background: #05b6d8;
    color: #ffffff
}

.appointmentDetailsMain .header {
    height: unset;
    min-height: unset;
    overflow: hidden
}

.appointmentDetailsMain .text-uppercase {
    text-transform: uppercase
}

.appointmentDetailsMain .text-center {
    text-align: center
}

.appointmentDetailsMain .no-data {
    text-align: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 300px;
    font-weight: 600;
    color: #25313E;
    font-size: 1.5rem
}

@media screen and (min-width:1024px) {
    .appointmentDetailsMain {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex
    }

    .appointmentDetailsMain .info-panel {
        width: 40%;
        padding: 0;
        padding-left: 2rem
    }

    .appointmentDetailsMain .info-panel .greeting-header {
        display: none
    }

    .appointmentDetailsMain .service-panel {
        -webkit-box-flex: 1;
        -ms-flex: 1;
        flex: 1
    }
}

.custom-checkbox {
    display: block;
    position: relative;
    padding-top: 3px;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 16px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.custom-checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0
}

.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    border-radius: 5px;
    background-color: #b8e1ff
}

.custom-checkbox:hover input~.checkmark {
    background-color: #05b6d8
}

.custom-checkbox input:checked~.checkmark {
    background-color: #05b6d8
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none
}

.custom-checkbox input:checked~.checkmark:after {
    display: block
}

.custom-checkbox .checkmark:after {
    left: 8px;
    top: 3px;
    width: 6px;
    height: 12px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg)
}

p{
    margin: 0px;
    padding: 0;
    margin-bottom: 0px !important;
}

.loader {
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid #3498db;
    width: 120px;
    height: 120px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
    position: fixed;
    left: calc(50% - 60px);
    top: calc(50% - 60px);
  }
  
  /* Safari */
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  .error {
      border: 5px solid red;
  }

  .ant-notification-topRight {
    top: calc(100vh - 60%) !important;
    left: calc(50% - 200px) !important;
    right: unset !important;
    bottom: unset !important;
  }

  .booking-notification-success .ant-notification-notice-message, .booking-notification-success .ant-notification-notice-description {
    font-size: 30px !important;
  }

  .custom-notification {
    width: 700px;
    max-width: calc(100vw - 24px * 2);
    padding: 50px 24px;
    overflow: hidden;
    line-height: 1.5715;
    word-wrap: break-word;
    background: red;
    border-radius: 2px;
    box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%);
    text-align: center;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
  }

  .green-popup {
      background-color: green;
  }

  .custom-notification .message {
      font-size: 30px;
      color: white;
  }

  .submit-button {
      font-size: 30px;
  }

  #customerName {
      text-transform: uppercase;
  }

  .review-title {
      font-weight: bold;
      color: #202020
  }

  .react-stars {
      margin-top: 0;
      margin-bottom: 20px;
      margin-left: auto;
      margin-right: auto
  }

  .react-stars span {
      height: 80px;
      padding-top: 10px
  }

  .react-stars:focus {
      outline: none
  }

  textarea {
      padding: 15px;
      width: 60%;
      min-width: 300px;
      max-width: 100%;
      height: 200px;
      resize: none;
      font-size: 1.375rem;
      font-weight: 500;
      margin-bottom: 30px
  }

  textarea:focus {
      outline: none
  }

  .review-option {
      margin-bottom: 20px;
      margin-top: 20px
  }

  .review-option .yelp {
      max-width: 320px
  }

  .review-option img {
      margin-bottom: 10px;
      max-width: 350px
  }

  .review-option__note {
      font-size: 1.15rem;
      text-transform: uppercase;
      color: #d61673;
      max-width: 380px;
      margin-top: 20px
  }

  .review-flex {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center
  }

  .review-flex div {
      align-self: flex-end;
      margin-right: 10px
  }

  .review-note-container {
      margin-left: auto;
      margin-right: auto
  }

  .review-note {
      font-size: 2rem;
      line-height: 1.5;
      text-align: center
  }

  .review-note span {
    font-size: 42px;
    line-height: 2.5;
    font-weight: 700;
}